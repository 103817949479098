<template>
  <div class="container_forgot_password">
    <Navbar/>
    <div class="container_forgot_password__container_form">
      <b-container class="h-100">
        <b-row align-h="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="4" align-self="center">
            <form @submit.prevent="sendForm">
              <b-card no-body>
                <b-container>
                  <b-row>
                    <b-col>
                      <h3 class="mb-4">Forgot password</h3>
                      <!-- Email -->
                      <b-form-group class="mb-3">
                        <b-form-input type="email" placeholder="Email" trim
                          :class="{'is-invalid': $v.form.email.$error}" v-model.trim="$v.form.email.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback">
                          <span v-if="!$v.form.email.required">The Email is required</span>
                          <span v-if="!$v.form.email.email">Invalid Email format</span>
                          <span v-if="!$v.form.email.maxLength">
                            The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Recaptcha -->
                      <vue-recaptcha ref="recaptcha" sitekey="6LfZ-wYfAAAAAP7rkAPUU3RKiJixfyVmN4Ytz0TC" 
                        size="invisible" @verify="validateRecaptcha" @expired="validateRecaptcha">
                      </vue-recaptcha>

                      <!-- Button -->
                      <b-button type="submit" variant="primary" class="mb-4 w-100">Send a reset password link</b-button>
                      <div class="w-100 text-center">
                        <router-link :to="{ name: 'login' }" class="text-decoration-none forgot fw-bold link-primary">
                          Go to Login
                        </router-link>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, email, maxLength} from 'vuelidate/lib/validators';
import Navbar from '@/components/Auth/Navbar.vue';
import VueRecaptcha from 'vue-recaptcha';
import Footer from '@/components/Footer.vue';

export default {
  name: 'ForgotPassword',
  components: {
    Navbar,
    VueRecaptcha,
    Footer
  },
  data () {
    return {
      form: {
        email: String(),
        recaptcha: null,
      },
      load: false,
    }
  },
  validations: {
    form: {
      email: { required, email, maxLength: maxLength(60) },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.generateRecaptcha();
      }
    },
    validateRecaptcha(response){
      this.$refs.recaptcha.reset();
      this.form.recaptcha = response;

      this.$http.post('password/email', this.form).then((response) => {
        if (response.status === 200) {
          this.$notify({ group: 'notifications_home', text: response.body.message, type: 'info' });
        }
        this.load = false;
      }, (error) => {
        if (error.status === 400) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        } else if (error.status === 500) {
          this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
        }
        this.load = false;
      });
    },
    generateRecaptcha(){
      this.$refs.recaptcha.execute();
    },
  }
}
</script>
